import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["idea", "ideaStatus", "favorite"]

    connect() {
        console.log("all idea controller connected")
    }

    like() {
        Rails.ajax({
            url: '/ideas/' + this.ideaTarget.id + '/like',
            type: 'post',
            data: '',
            success: (data, status, _xhr) => {
                if (xhr.status === 200) {
                    console.log("inserted")
                    this.showPoints(true, data);
                } else {
                    console.log('fail')
                }
            }
        })
    }
    dislike() {
        Rails.ajax({
            url: '/ideas/' + this.ideaTarget.id + '/dislike',
            type: 'post',
            data: '',
            success: (data, status, _xhr) => {
                if (xhr.status === 200) {
                    console.log("inserted")
                    this.showPoints(false, data);
                } else {
                    console.log('fail')
                }
            }
        })
    }
    showPoints(like, data) {
        var html = '';

        if (like == true) {
            html += "<img src=\"/images/icons/thumb_up-green.svg\" class=\"up2\">";
        } else {
            html += "<img src=\"/images/icons/thumb_up_down-red.svg\" class=\"down2\">";
        }

        if (data.points >= 0) {
            html += " úspěšnost <strong class=\"green\">+" + data.points + "</strong>";
        } else {
            html += " úspěšnost <strong class=\"red\">" + data.points + "</strong>";
        }

        this.ideaTarget.querySelector("#voting-available").remove();
        this.ideaTarget.insertAdjacentHTML('beforeend', html);
    }

    favorite() {
        const idea_id = this.favoriteTarget.dataset.info;

        this.favoriteTarget.insertAdjacentHTML("afterend", '<a class="idea-unfavorite" title="odstranit z oblíbených" id="idea_' + idea_id + '_favorite_link" data-action="idea#unfavorite" data-target="idea.favorite" data-info="' + idea_id + '" href="#">Odstranit z oblíbených</a>');
        this.favoriteTarget.removeChild(this.favoriteTarget.childNodes[0]);

        $.ajax({
            type: 'POST',
            url: '/ideas/' + idea_id + '/favorite.json',
            success: function (data) { console.log("add to favorite"); },
            cache: false
        });
    }

    unfavorite() {
        const idea_id = this.favoriteTarget.dataset.info;
        
        this.favoriteTarget.insertAdjacentHTML('afterend', '<a class="idea-unfavorite" id="idea_' + idea_id + '_favorite_link" data-action="idea#favorite" data-target="idea.favorite" data-info="' + idea_id + '" href="#">Přidat do oblíbených</a>');
        this.favoriteTarget.removeChild(this.favoriteTarget.childNodes[0]);

        $.ajax({
            type: 'DELETE',
            url: '/ideas/' + idea_id + '/unfavorite.json',
            success: function (data) { console.log("remove from favorite"); },
            cache: false
        });
    }

    changeIdeaStatus() {
        const idea_id = this.ideaStatusTarget.dataset.info;
        const status = event.target.value

        $.ajax({
            type: 'PUT',
            url: '/ideas/' + idea_id + '/change_status',
            data: { status: status },
            success: function () {
                location.reload();
            }
        })
    }
}
