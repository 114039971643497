import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["occupationSelect", "workArea"]

    updateWorkArea() {
        if (this.occupationSelectTarget.value === "work") {
            this.workAreaTarget.classList.remove('d-none')
            this.workAreaTarget.querySelector('select').required = true
        } else {
            this.workAreaTarget.classList.add('d-none')
            this.workAreaTarget.querySelector('select').required = false
        }
    }
}