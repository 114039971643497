(function () {
    function r(e, n, t) {
        function o(i, f) {
            if (!n[i]) {
                if (!e[i]) {
                    var c = "function" == typeof require && require;
                    if (!f && c) return c(i, !0);
                    if (u) return u(i, !0);
                    var a = new Error("Cannot find module '" + i + "'");
                    throw a.code = "MODULE_NOT_FOUND", a
                }
                var p = n[i] = {exports: {}};
                e[i][0].call(p.exports, function (r) {
                    var n = e[i][1][r];
                    return o(n || r)
                }, p, p.exports, r, e, n, t)
            }
            return n[i].exports
        }

        for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) o(t[i]);
        return o
    }

    return r
})()({
    1: [function (require, module, exports) {
        "use strict";

//require('./lib/fancybox/jquery.fancybox.pack');

        // example survey JS (pacifier, beer, stones)
        $(document).ready(function () {
            $(".example-research-container > .form-group.visible").click(function (event) {
                event.stopPropagation();
                event.preventDefault();
                $(".example-research-container .form-group").toggleClass("d-none");
                // $("#reg-form-title").text("Bavilo vás to? Staňte se výzkumníkem");
                // $("input#user_email").focus();
                $(".example-research-container").toggleClass("animate");
                $('.count').each(function () {
                    $(this).prop('Counter',0).animate({
                        Counter: $(this).text()
                    }, {
                        duration: 2000,
                        easing: 'swing',
                        step: function (now) {
                            $(this).text(Math.ceil(now));
                        }
                    });
                });

            })
        })

        $(document).scroll(function () {
            $(".fixed-top").toggleClass("scrolled", $(document).scrollTop() > 0);

            if ($(this).scrollTop() > 300) {
                $('.up').fadeIn();
            } else {
                $('.up').fadeOut();
            }
        });
        $(window).on('load', function () {
            if ($(window).scrollTop()) {
                $(".fixed-top").addClass("scrolled");
            }
        });

        $(document).ready(function () {
            $(".hamburger").on("click", function () {
                $(this).toggleClass("is-active");
            });

            var screen = $(window);
            if (screen.width() < 580) {
                $('.selectpicker').selectpicker({
                    mobile: true
                });
            } else {
                $('.selectpicker').selectpicker();
            }

            // dog pop-out on landing page
            setTimeout(function () {
                $('.krasty-body').toggleClass('hidden');
                $('.krasty-head').toggleClass('hidden');
                $('.krasty-paw').toggleClass('hidden');
            }, 500);


            // fancybox
            // disabled (and not imported) until needed
            // $(".fancybox").fancybox();

            // fancybox hijacking images
            // $('.article-detail .right p img').each(function () {
            //     var width = $(this).prop('width');
            //     var height = $(this).prop('height');
            //     var alt = $(this).prop('alt');
            //     var thumb_src = $(this).prop('src');
            //     var original_src = thumb_src.replace(/(ckeditor_assets\/pictures\/\d+)\/content_/g, '\$1/original_');
            //     $(this).replaceWith('<a href="' + original_src + '" title="' + alt + '" class="fancybox" rel="group"><img src="' + thumb_src + '" alt="' + alt + '" width="' + width + '" height="' + height + '"></a>');
            // });
        });

        $(document).on("click", ".alert", function (e) {
            console.log(e);
            if (e.target.nodeName != "A") {
                $(this).fadeOut('slow');
            }
        });

        $(document).on("click", ".ajax-pagination", function (e) {
            e.preventDefault();
            e.stopPropagation();

            var button = $(this);
            var link = button.attr("href");

            $.ajax({
                url: link,
                method: "get",
                beforeSend: function beforeSend() {
                    button.text("Načítám...").addClass("disabled");
                },
                success: function success(r) {
                    button.remove();
                    $("#news-items-list").append(r);
                },
                error: function error(e) {
                    button.text("Načíst další články").removeClass("disabled");
                }
            });
        });

        $("#registrationButton").on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            console.log($("#conditions").is("checked"));
            return false;
        });

        function validateRegisterForm() {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            $(".reg-form-error").addClass("d-none");
            $("#user_email").removeClass("has-error");
            if (!$("#user_email").val()) {
                $("#user_email").addClass("has-error");
                $("#email-error").removeClass("d-none");
                return false;
            } else if (!re.test($("#user_email").val())) {
                $("#user_email").addClass("has-error");
                $("#email-format-error").removeClass("d-none");
                return false;
            } else if (!$("#conditions").is(":checked")) {
                $("#conditions-error").removeClass("d-none");
                return false;
            }
            return true;
        }

        function validateUpdateProfileForm() {
            $(".reg-form-error").addClass("d-none");
            if (!$("#conditions").is(":checked")) {
                $("#conditions-error").removeClass("d-none");
                return false;
            }
            return true;
        }

        var validateUnemployment = function validateUnemployment(position) {
            if ($('.user_status option').index($('.user_status option:selected')) != 2) {
                $('.employment').hide();
                $('.employment option:selected').prop("selected", false);
                index = filled_fields.indexOf(12);
                if (index != -1) {
                    filled_fields.splice(index, 1);
                }
            } else {
                $('.employment').show();
            }
        };

    }, {}]
}, {}, [1]);
