// copied and customized from https://www.jorgemanrubia.com/2019/02/16/form-validations-with-html5-and-modern-rails/

import {Controller} from 'stimulus'

export default class extends Controller {

    fieldContainerClass = 'form-group'
    errorMessageClass = 'error-info'
    invalidFormGroupClass = 'has-error'
    invalidFieldClass = 'invalid'

    connect() {
        console.log("validation-messages controller connected")
        this.element.setAttribute('novalidate', true)

        for (const elem of this.formFields) {
            elem.addEventListener('change', this.onValueChange, true)
        }

        // this.element.addEventListener('blur', this.onBlur, true)
        this.element.addEventListener('submit', this.onSubmit)
    }

    disconnect() {
        this.element.removeEventListener('blur', this.onValueChange)
        this.element.removeEventListener('submit', this.onSubmit)
    }

    onValueChange = (event) => {
        this.validateField(event.target)
    }

    onSubmit = (event) => {
        if (!this.validateForm()) {
            event.preventDefault()
            this.firstInvalidField.focus()
        }
    }

    validateForm() {
        let isValid = true
        // Not using `find` because we want to validate all the fields
        this.formFields.forEach((field) => {
            if (this.shouldValidateField(field) && !this.validateField(field)) {
                isValid = false
            }
        })
        return isValid
    }

    validateField(field) {
        if (!this.shouldValidateField(field))
            return true
        const isValid = field.checkValidity()

        field.classList.toggle(this.invalidFieldClass, !isValid)
        const container = this.getFieldContainerForField(field)
        if (container) {
            container.classList.toggle(this.invalidFormGroupClass, !isValid)
        }

        this.refreshErrorForInvalidField(field, isValid)

        return isValid
    }

    shouldValidateField(field) {
        return !field.disabled && !['file', 'reset', 'submit', 'button'].includes(field.type)
    }

    getFieldContainerForField(field) {
        const found = field.closest(`.${this.fieldContainerClass}`)

        if (!found) {
            console.log("field container not found")
        } else {
            return found
        }
    }

    refreshErrorForInvalidField(field, isValid) {
        this.removeExistingErrorMessage(field)
        if (!isValid)
            this.showErrorForInvalidField(field)
    }

    removeExistingErrorMessage(field) {
        const fieldContainer = this.getFieldContainerForField(field)
        if (!fieldContainer)
            return;
        const existingErrorMessageElement = fieldContainer.querySelector(`.${this.errorMessageClass}`)

        if (existingErrorMessageElement) {
            existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
        }
    }

    showErrorForInvalidField(field) {
        const fieldContainer = this.getFieldContainerForField(field)
        if (!fieldContainer) {
            return
        }
        fieldContainer.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(field))
    }

    buildFieldErrorHtml(field) {
        const customMessage = field.dataset.customErrorMessage
        const message = customMessage || field.validationMessage
        return `<p class="${this.errorMessageClass}">${message}</p>`
    }

    get formFields() {
        // return Array.from(this.element.elements)
        return Array.from(this.element.querySelectorAll('input, select'))
    }

    get firstInvalidField() {
        return this.formFields.find(field => !field.checkValidity())
    }
}